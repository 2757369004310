import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const JobBody = loadable(() => import("@organisms/JobBody"));

const JobSinglePage = ({ data }) => {
  const { uid, meta, blocks } = data;
  return (
    <PageContainer meta={meta} path={uid}>
      <JobBody blocks={blocks} meta={meta} />
    </PageContainer>
  );
};

export default JobSinglePage;
